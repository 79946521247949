
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.edit-connections-modal {
  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-black-op-10;
    padding: calc(#{$base-gutter} / 2) 0;
  }
  &__item-name {
    flex-grow: 1;
    margin-right: $base-gutter;
  }
  &__item-actions {
    display: flex;
    flex-shrink: 0;
  }
}
